import { useRouteError } from "react-router-dom";
import Alert from "@cloudscape-design/components/alert";
import App from './App';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <App>
            <Alert
                statusIconAriaLabel="Error"
                type="error"
                header="Opps! An error has occurred."
            >
                {error.statusText || error.message}
            </Alert>
        </App>
    );
}