import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth'
import {
    Container,
    Header,
    Table,
    Box,
    SpaceBetween,
    Button,
    Flashbar,
    Link,
    Pagination,
    StatusIndicator
} from '@cloudscape-design/components';
import {
    useNavigate,
} from "react-router-dom";

// TODO auto refresh table
// TODO add search to table, add filters to table
function getDate(date) {
    var a = new Date(date * 1000);
    var year = a.getFullYear();
    var month = String(a.getMonth() + 1).padStart(2, '0');
    var day = String(a.getDate()).padStart(2, '0');
    var hour = String(a.getHours()).padStart(2, '0');
    var min = String(a.getMinutes()).padStart(2, '0');
    var time = `${day}/${month}/${year}, ${hour}:${min}`;
    return time;
}

function TranscriptionList() {
    const { state } = useLocation();
    const [flashItem, setFlashItem] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [
        selectedItem,
        setSelectedItem
    ] = useState([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const reload = useCallback(async () => {
        setIsLoading(true)
        setSelectedItem([])
        setFlashItem([])
        const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
        if (authToken) {
            const restOperation = get({
                apiName: 'transcribe',
                path: '/transcriptions',
                options: {
                    headers: {
                        Authorization: authToken
                    }
                },
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            const array = (json)
            const pageSize = 10;
            const paginatedArray = [];
            for (let i = 0; i < array.length; i += pageSize) {
                const page = array.slice(i, i + pageSize);
                paginatedArray.push(page);
            }
            setFiles(paginatedArray);
            if (paginatedArray.length < currentPage) {
                setCurrentFiles(paginatedArray[0])
                setCurrentPage(1)
            } else {
                setCurrentFiles(paginatedArray[currentPage - 1])
            }

            setPageCount(paginatedArray.length)
        }
        setIsLoading(false)
    }, [currentPage])

    useEffect(() => {
        reload();
    }, [reload]);

    useEffect(() => {

        if (state) {
            setFlashItem([
                {
                    type: "success",
                    content: state.message,
                    dismissible: true,
                    dismissLabel: "Dismiss message",
                    onDismiss: () => setFlashItem([]),
                    id: "message_1"
                }
            ])
        }


    }, [state]);

    const handlePageChange = ({ detail }) => {
        setCurrentPage(detail.currentPageIndex)
        setCurrentFiles(files[detail.currentPageIndex - 1])
    }
    // TODO allow sorting by date in table
    return (
        <Container
            header={
                <Header
                    variant="h2"
                    actions={<SpaceBetween direction="horizontal" size="xs">

                        <Button variant="primary" onClick={() => navigate('/audio-transcribe/new')}>
                            New Transcription
                        </Button>
                        <Button iconName="refresh" variant="normal" onClick={reload}>
                            Refresh
                        </Button>
                    </SpaceBetween>}
                >
                    My Transcriptions
                </Header>

            }
        >
            <Flashbar items={state ? flashItem : []} />

            <Table
                variant='borderless'
                columnDefinitions={[
                    {
                        id: "date",
                        header: "Date",
                        cell: item => getDate(item.file_timestamp)
                    },
                    {
                        id: "name",
                        header: "Name",
                        cell: item => <Link
                            onFollow={() =>
                                navigate(
                                    `/audio-transcribe/${item.file_name}`,
                                    { state: { item: item } }

                                )
                            }
                        >
                            {item.file_original}
                        </Link>

                    },
                    {
                        id: "status",
                        header: "Status",
                        cell: item => (item.state === "complete") ?
                            <StatusIndicator>Complete</StatusIndicator> :
                            <StatusIndicator type="pending">Pending</StatusIndicator>

                    }
                ]}
                columnDisplay={[
                    { id: "name", visible: true },
                    { id: "date", visible: true },
                    { id: "status", visible: true },

                ]}
                pagination={
                    <Pagination
                        currentPageIndex={currentPage}
                        pagesCount={pageCount}
                        onChange={handlePageChange}

                    />
                }

                enableKeyboardNavigation
                items={currentFiles}
                trackBy="file_name"
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No transcriptions</b>
                            <Button variant='primary' onClick={() => navigate('/audio-transcribe/new')}>New Transcription</Button>
                        </SpaceBetween>
                    </Box>
                }
                loading={isLoading}
                loadingText={"Loading Transcriptions"}
            />
        </Container >
    )
}

export default TranscriptionList;