
import {
    ContentLayout,
    Header,
    Link,
    Cards
} from '@cloudscape-design/components';
import {
    useNavigate
} from "react-router-dom";

function Home() {
    const navigate = useNavigate();
    let cityTraxItems = [];
    if (window.config.audioTranscribeApiEndpoint !== undefined) {
        cityTraxItems.push({
            name: "Audio Transcribe",
            description: "Transcribe and summarise audio files",
            href: '/audio-transcribe/'
        })
    }

    return (
        <ContentLayout
            header={
                <Header variant="h1" description="We empower organisations">
                    City Trax
                </Header>
            }
        >
            <Cards
                ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                }}
                cardDefinition={{
                    header: item => (
                        <Link fontSize='heading-l' onFollow={() =>
                            navigate(item.href)
                        }>{item.name}</Link>
                    ),
                    sections: [
                        {
                            id: "description",
                            content: item => item.description
                        }
                    ]
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 500, cards: 2 }
                ]}
                items={cityTraxItems}
                header={<Header>Applications</Header>}
            />

        </ContentLayout>

    )

}

export default Home;