import { useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { post, ApiError } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
    Form,
    SpaceBetween,
    Button,
    FormField,
    FileUpload,
    ProgressBar,
    Spinner
} from '@cloudscape-design/components';
import SummaryTypeFormField from './SummaryTypeFormField';
import EnableActionsFormField from './EnableActionsFormField';
import SelectTemplateFormField from './SelectTemplateFormField';

function UploadForm() {

    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorSubmittingMessage, setErrorSubmittingMessage] = useState('');
    const [audioFile, setAudioFile] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [summaryType, setSummaryType] = useState({ label: "Standard", value: "3" });
    const [detectActions, setDetectActions] = useState(false);
    const [template, setTemplate] = useState({ label: "None", value: "0" })


    const validateAudioFile = (file) => {
        console.log(file)
        const fileSizeLimit = 2000000000 //2Gb file size limit for AWS transcribe
        if (!file) {
            return 'Please select an audio file'
        } else if (!["audio/mpeg", "audio/x-m4a", "video/webm", "audio/x-wav", "audio/ogg", "video/mp4", "audio/flac", "audio/amr"].includes(file[0].type)) {
            return 'File type not supported';
        } else if (file[0].size > fileSizeLimit) {
            return 'File size too large, please use a smaller file'
        } else {
            return undefined
        }
    }

    // TODO handle large file uploads in multipart
    // TODO handle file uploads from google drive, requires a google app account
    const handleSubmit = useCallback(async () => {
        if (isSubmitting) return;

        const error = validateAudioFile(audioFile)
        if (error !== undefined) {
            setErrorSubmittingMessage(error);
        } else {

            setIsSubmitting(true);
            setErrorSubmittingMessage('');
            try {
                const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
                if (authToken) {
                    const restOperation = post({
                        apiName: 'transcribe',
                        path: '/transcription',
                        options: {
                            queryParams: {
                                file: audioFile[0].name
                            },
                            headers: {
                                Authorization: authToken
                            },
                            body: {
                                summaryType: summaryType,
                                detectActions: detectActions,
                                template: template
                            },
                        },

                    });
                    const { body } = await restOperation.response;
                    const json = await body.json();
                    const pre_signed_url = (json)?.pre_signed_url
                    console.log(pre_signed_url)
                    await new Promise((resolve, reject) => {
                        const xhr = new XMLHttpRequest();
                        xhr.upload.addEventListener('progress', e => {
                            setUploadProgress(Math.round((e.loaded / audioFile[0].size) * 100))
                        });

                        xhr.addEventListener('load', () => resolve({ status: xhr.status, body: xhr.responseText }));
                        xhr.addEventListener('error', () => reject(new Error('File upload failed')));
                        xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
                        xhr.open('PUT', pre_signed_url, true);
                        xhr.send(audioFile[0]);
                    });
                    setIsSubmitting(false);
                    navigate('/audio-transcribe/', {
                        state: { message: "Successfully uploaded audio file" }
                    });
                }

            } catch (error) {
                if (error instanceof ApiError) {
                    if (error.response) {
                        const {
                            statusCode,
                            body
                        } = error.response;
                        console.error(`Received ${statusCode} error response with payload: ${body}`);
                        setErrorSubmittingMessage(body);
                    }
                    // Handle API errors not caused by HTTP response.
                }
                console.error(error)
                setIsSubmitting(false);
                setErrorSubmittingMessage("Error submitting file");
            }
        }

    }, [isSubmitting, audioFile, navigate, summaryType, detectActions, template]);

    return (
        <>
            <form onSubmit={e => e.preventDefault()}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button formAction="none" variant="link" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={isSubmitting | (audioFile.length === 0)}
                            >{isSubmitting ? <Spinner /> : "Submit"}</Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField
                            label="Upload an Audio File"
                        >
                            <FileUpload
                                onChange={({ detail }) => {
                                    setErrorSubmittingMessage('')
                                    setAudioFile(detail.value)
                                }}
                                value={audioFile}
                                i18nStrings={{
                                    uploadButtonText: e =>
                                        e ? "Choose files" : "Choose file",
                                    dropzoneText: e =>
                                        e
                                            ? "Drop files to upload"
                                            : "Drop file to upload",
                                    removeFileAriaLabel: e =>
                                        `Remove file ${e + 1}`,
                                    limitShowFewer: "Show fewer files",
                                    limitShowMore: "Show more files",
                                    errorIconAriaLabel: "Error"
                                }}
                                showFileLastModified
                                showFileSize
                                showFileThumbnail
                                tokenLimit={3}
                                accept=".mp3, .m4a, .amr, .flac, .mp4, .wav, .webm"
                                constraintText="Accepted Formats: .mp3, .m4a, .amr, .flac, .mp4, .wav, .webm"
                                fileErrors={[errorSubmittingMessage]}
                            />
                        </FormField>
                        <SummaryTypeFormField summaryType={summaryType} setSummaryType={setSummaryType} newTranscription={true} />
                        {summaryType.label !== 'None' ?
                            <EnableActionsFormField detectActions={detectActions} setDetectActions={setDetectActions} />
                            : null
                        }
                        <SelectTemplateFormField template={template} setTemplate={setTemplate} newTranscription={true} />

                    </SpaceBetween>
                </Form>
            </form>
            {
                isSubmitting ? <ProgressBar
                    value={uploadProgress}
                    additionalInfo="Uploading Audio File"
                /> : null}
        </>
    )

}

export default UploadForm;