import {
    FormField,
    Checkbox
} from '@cloudscape-design/components';



function EnableActionsFormField(props) {

    return (

        < FormField
            label="Detect Actions"
            description="Include a list of meeting actions with the summary"
        >
            <Checkbox
                onChange={({ detail }) => {
                    console.log(detail.checked)
                    props.setDetectActions(detail.checked)
                }
                }
                checked={props.detectActions}
            >

            </Checkbox>
        </FormField >
    )
}

export default EnableActionsFormField;

