
import {
    ContentLayout,
    Header,
} from '@cloudscape-design/components';
import { Outlet } from "react-router-dom";

function AudioTranscribe() {

    return (
        <ContentLayout
            header={
                <Header variant="h1" description="Transcribe and summarise audio files">
                    Transcribe
                </Header>
            }
        >

            <Outlet />
        </ContentLayout>

    )

}

export default AudioTranscribe;