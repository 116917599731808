import {
    Container,
    Header,
    Tabs
} from '@cloudscape-design/components';
import UploadForm from './UploadForm';
import RecordForm from './RecordForm';




function TranscriptionForm() {
    return (
        <Container
            header={
                <Header variant="h2">
                    Create New Transcription
                </Header>
            }
        >
            <Tabs
                tabs={[
                    {
                        label: "Upload",
                        id: "upload",
                        content: <UploadForm />

                    },
                    {
                        label: "Record",
                        id: "record",
                        content: <RecordForm />
                    }
                ]}
            />



        </Container >




    )



}

export default TranscriptionForm;