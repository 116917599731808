import {
    Container,
    Header,
    Form,
    SpaceBetween,
    Button,
    Spinner
} from '@cloudscape-design/components';
import SelectTemplateFormField from './SelectTemplateFormField';
import { useNavigate, useLocation } from "react-router-dom";
import { useCallback, useState } from 'react';
import { post, ApiError } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';




function PopulateTemplateForm() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { file_name, file_original } = state;
    console.log(state)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [template, setTemplate] = useState()

    const handleSubmit = useCallback(async () => {
        if (isSubmitting) return;

        setIsSubmitting(true);

        try {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            if (authToken) {
                const restOperation = post({
                    apiName: 'transcribe',
                    path: '/template',
                    options: {
                        queryParams: {
                            file: file_name
                        },
                        headers: {
                            Authorization: authToken
                        },
                        body: {
                            template: template
                        },
                    },

                });
                const { body } = await restOperation.response;
                const json = await body.json();
                // const pre_signed_url = (json)?.pre_signed_url
                setIsSubmitting(false);
                navigate('/audio-transcribe/', {
                    state: { message: "Successfully triggered the population of a template" }
                });
            }

        } catch (error) {
            if (error instanceof ApiError) {
                if (error.response) {
                    const {
                        statusCode,
                        body
                    } = error.response;
                    console.error(`Received ${statusCode} error response with payload: ${body}`);
                }
                // Handle API errors not caused by HTTP response.
            }
            console.error(error)
            setIsSubmitting(false);
        }



    }, [file_name, template, navigate]);

    return (
        <Container
            header={
                <>
                    <Header variant="h2" description={file_original}>
                        Populate Template
                    </Header>
                </>
            }
        >
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                        >{isSubmitting ? <Spinner /> : "Submit"}</Button>
                    </SpaceBetween>
                }
            >
                <SpaceBetween direction="vertical" size="l">
                    <SelectTemplateFormField template={template} setTemplate={setTemplate} />


                </SpaceBetween>
            </Form>


        </Container >
    )



}

export default PopulateTemplateForm;